import {useMemo} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useMasterLayout} from 'src/app/context/MasterContext'
import DashboardScreens from 'src/app/modules/dashboard/Screens'
import InventoryScreen from 'src/app/modules/inventory/InventoryScreen'
import MasterDataScreen from 'src/app/modules/master-data/MasterDataScreen'
import {ModulePermissionQuery} from 'src/app/modules/permissions/models/Role.model'
import {User} from 'src/app/modules/permissions/models/User.model'
import PurchasingScreen from 'src/app/modules/purchasing/PurchasingScreen'
import {cn} from 'src/app/utils/cn-utils'
import {RootState} from 'src/setup'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import ReportScreen from 'src/app/modules/report/ReportScreen'

export interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  module_permissions?: ModulePermissionQuery
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  hidden?: boolean
  disabled?: boolean
}

const useMenus = (user?: User): Menu[] => {
  return useMemo(
    () =>
      [
        {
          // title: 'Building Blocks',
          children: [
            {
              to: DashboardScreens.DASHBOARD.PATH.replace(':type', 'live-view'),
              icon: '/media/icons/IconDashboard2.svg',
              title: DashboardScreens.DASHBOARD.TITLE,
              // module_permissions: DashboardScreens.DASHBOARD.PERMISSION,
            },
            {
              to: PurchasingScreen.PURCHASING.PATH,
              icon: '/media/icons/IconCart.svg',
              title: PurchasingScreen.PURCHASING.TITLE,
              // module_permissions: DashboardScreens.DASHBOARD.PERMISSION,
              children: [
                {
                  to: PurchasingScreen.PURCHASE_REQUEST_LIST.PATH,
                  activePath: PurchasingScreen.PURCHASE_REQUEST_LIST.PATH,
                  title: PurchasingScreen.PURCHASE_REQUEST_LIST.TITLE,
                  hasBullet: true,
                },
                {
                  to: PurchasingScreen.PURCHASE_ORDER_LIST.PATH,
                  activePath: PurchasingScreen.PURCHASE_ORDER_LIST.PATH,
                  title: PurchasingScreen.PURCHASE_ORDER_LIST.TITLE,
                  hasBullet: true,
                },
              ],
            },
            {
              to: InventoryScreen.INVENTORY.PATH,
              icon: '/media/icons/IconWarehouse.svg',
              title: InventoryScreen.INVENTORY.TITLE,
              children: [
                {
                  to: InventoryScreen.ITEM_FULFILLMENT_LIST.PATH,
                  activePath: InventoryScreen.ITEM_FULFILLMENT_LIST.PATH,
                  title: InventoryScreen.ITEM_FULFILLMENT_LIST.TITLE,
                  hasBullet: true,
                },
                {
                  to: InventoryScreen.GOOD_RECEIPT_LIST.PATH,
                  activePath: InventoryScreen.GOOD_RECEIPT_LIST.PATH,
                  title: InventoryScreen.GOOD_RECEIPT_LIST.TITLE,
                  hasBullet: true,
                },
                {
                  to: InventoryScreen.INVENTORY_ADJUSTMENT_LIST.PATH,
                  activePath: InventoryScreen.INVENTORY_ADJUSTMENT_LIST.PATH,
                  title: InventoryScreen.INVENTORY_ADJUSTMENT_LIST.TITLE,
                  hasBullet: true,
                },
                {
                  to: InventoryScreen.TRANSFER_ORDER_LIST.PATH,
                  activePath: InventoryScreen.TRANSFER_ORDER_LIST.PATH,
                  title: InventoryScreen.TRANSFER_ORDER_LIST.TITLE,
                  hasBullet: true,
                },
                {
                  to: InventoryScreen.DELIVERY_ORDER_LIST.PATH,
                  activePath: InventoryScreen.DELIVERY_ORDER_LIST.PATH,
                  title: InventoryScreen.DELIVERY_ORDER_LIST.TITLE,
                  hasBullet: true,
                },
              ],
            },
            {
              to: MasterDataScreen.MASTER_DATA.PATH,
              icon: '/media/icons/IconFolder.svg',
              title: MasterDataScreen.MASTER_DATA.TITLE,
              // module_permissions: DashboardScreens.DASHBOARD.PERMISSION,
            },
            {
              to: ReportScreen.REPORT.PATH,
              icon: '/media/icons/IconReport.svg',
              title: ReportScreen.REPORT.TITLE,
              children: [
                {
                  to: ReportScreen.USER_LOG_REPORT.PATH,
                  activePath: ReportScreen.USER_LOG_REPORT.PATH,
                  title: ReportScreen.USER_LOG_REPORT.TITLE,
                  hasBullet: true,
                },
                {
                  to: ReportScreen.TRANSCTION_LOG_REPORT.PATH,
                  activePath: ReportScreen.TRANSCTION_LOG_REPORT.PATH,
                  title: ReportScreen.TRANSCTION_LOG_REPORT.TITLE,
                  hasBullet: true,
                },
                {
                  to: ReportScreen.LOGIN_EVEN_REPORT.PATH,
                  activePath: ReportScreen.LOGIN_EVEN_REPORT.PATH,
                  title: ReportScreen.LOGIN_EVEN_REPORT.TITLE,
                  hasBullet: true,
                },
              ],
              // module_permissions: DashboardScreens.DASHBOARD.PERMISSION,
            },
            // {
            //   to: UserRoleScreens.USER_ROLE.PATH,
            //   icon: '/media/icons/IconUser.svg',
            //   title: UserRoleScreens.USER_ROLE.TITLE,
            //   children: [
            //     {
            //       to: UserRoleScreens.LIST_USER.PATH,
            //       activePath: UserRoleScreens.LIST_USER.PATH,
            //       title: UserRoleScreens.LIST_USER.TITLE,
            //       hasBullet: true,
            //       // module_permissions: UserRoleScreens.LIST_USER.PERMISSION,
            //     },
            //     {
            //       to: UserRoleScreens.LIST_ROLE.PATH,
            //       activePath: UserRoleScreens.LIST_ROLE.PATH,
            //       title: UserRoleScreens.LIST_ROLE.TITLE,
            //       hasBullet: true,
            //       // module_permissions: UserRoleScreens.LIST_ROLE.PERMISSION,
            //     },
            //   ],
            // },
          ],
        },
      ] as Menu[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )
}

export const GeneratedMenu: React.FC<{menu: Menu}> = ({menu}) => {
  const {minimize} = useMasterLayout()

  if (!menu.to) {
    if (!menu.children || menu.children.length === 0) return null
    return (
      <>
        <div className='block p-0'>
          <div className='px-6 pt-8 pb-2'>
            <span
              className={cn('tracking-widest text-white uppercase text-fs-10', {
                hidden: minimize,
              })}
            >
              {menu.title}
            </span>
          </div>
        </div>
        {menu.children?.map((child) => {
          return <GeneratedMenu menu={child} key={child.id} />
        })}
      </>
    )
  }
  if (!menu.children)
    return (
      <AsideMenuItem
        to={menu.to}
        icon={menu.icon}
        title={menu.title}
        fontIcon={menu.fontIcon}
        hasBullet={menu.hasBullet}
        activePath={menu.activePath}
        badge={menu.badge}
        badgeCircle={menu.badgeCircle}
        disabled={menu.disabled}
      />
    )

  return (
    <AsideMenuItemWithSub
      to={menu.to}
      title={menu.title}
      fontIcon={menu.fontIcon}
      icon={menu.icon}
      activePath={menu.activePath}
      hasBullet={menu.hasBullet}
      disabled={menu.disabled}
    >
      {menu.children.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </AsideMenuItemWithSub>
  )
}

const filterMenus = (
  menus: Menu[] | undefined,
  predicate: (menu: Menu) => boolean
): Menu[] | undefined => {
  const result = menus?.map((menu, index) => ({
    ...menu,
    id: String(index),
    children: filterMenus(menu.children, predicate),
  }))
  return result?.filter((menu) => (!menu.children || menu.children.length > 0) && predicate(menu))
}

const AsideMenuData: React.FC = () => {
  const user: User = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as User
  const menus = useMenus(user)
  // const {hasAccess} = usePermissions()

  // const generated = useMemo(
  //   () =>
  //     filterMenus(menus, (menu) => hasAccess(menu?.module_permissions) && !Boolean(menu.hidden)),
  //   [menus, hasAccess]
  // )

  const generated = useMemo(() => filterMenus(menus, (menu) => !Boolean(menu.hidden)), [menus])
  return (
    <>
      {generated?.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </>
  )
}

export default AsideMenuData
